import axios from 'axios';
import { env } from '../../config';
import { getAxiosConfig } from '../../utils/constants';
// const env =  'https://api.eyes-aid.com/'

const SHOW_ALL_ORDERS = `${env}api/dashboard/order`;
const SHOW_ORDER = `${env}api/dashboard/order/`;
const UPDATE_ORDER_STATUS = `${env}api/order/order-status`;
const CARGUS_SHIP = `${env}api/cargus/ship/`;
const UPS_SHIP = `${env}api/ups/ship/`;


export const getAllOrders = async (productCategory: string, orderBy: string, page?: number) => {
  const actualPage = page ? page : 1;
  return axios
    .get(
      SHOW_ALL_ORDERS +
        '?category_id=' +
        productCategory +
        '&order_by=' +
        orderBy.substr(0, orderBy.indexOf(' ')) +
        '&order_type=' +
        orderBy.substr(orderBy.indexOf(' ') + 1) +
        '&page=' +
        actualPage.toString(),
      getAxiosConfig(),
    )
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.message);
    });
};

export const getOrderById = async (id: string) => {
  return axios
    .get(SHOW_ORDER + id, getAxiosConfig())
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.message);
    });
};

export const updateOrderStatus = async (orderId: string, status: string) => {
  const bodyParams = {
    order_id: orderId,
    status: status,
  };
  return axios
    .post(UPDATE_ORDER_STATUS, bodyParams, getAxiosConfig())
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.message);
    });
};

export const searchOrder = async (searchTerm: string) => {
  return axios
    .get(SHOW_ALL_ORDERS + '?query=' + searchTerm, getAxiosConfig())
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.message);
    });
};

export const createShipmentCargus = async (orderId: string) => {
  return axios
    .post(CARGUS_SHIP + orderId, getAxiosConfig())
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.message);
    });
};

export const createShipmentUps = async (orderId: string) => {
  return axios
    .post(UPS_SHIP + orderId, getAxiosConfig())
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.message);
    });
};
