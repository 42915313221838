export const TEXT_EN = {
  ADD_EYEGLASSES: 'Add eyeglasses',
  ADD_SUNGLASSES: 'Add sunglasses',
  ADD_COMPUTER_GLASSES: 'Add computer glasses',
  ADD_LENSES: 'Add lenses',
  ADD_COSMETIC_LENSES: 'Add cosmetic lenses',
  PRODUCT_DETAILS_FILTERS: 'Product details and search filters',
  BRAND: 'Brand',
  MODEL: 'Model',
  NUMBER_STOCK: 'Number of products in stock',
  FULL_PRICE: 'Full price',
  DISCOUNT_PRICE: 'Discount price',
  FINAL_PRICE: 'Final price after discount',
  DESCRIPTION: 'Description',
  FACE_SHAPE: 'Face shape',
  FRAME_SHAPE: 'Frame shape',
  FRAME_TYPE: 'Frame type',
  FRAME_MATERIALS: 'Frame materials',
  LENS_TYPES: 'Lens types',
  CHARACTERISTICS: 'Characteristics',
  FOR: 'For',
  SIZE: 'Size',
  LENSES_WIDTH: 'Lenses width',
  TEMPLE_LENGTH: 'Temple length',
  BRIDGE_WIDTH: 'Bridge width',
  ADD_SIZE: 'Add a new size +',
  FRAME_COLOR: 'Frame color',
  LENSES_COLOR: 'Lenses color',
  PRODUCT_IMAGES: 'Product images',
  ADD_IMAGE: '+ Add image',
  IMAGE_3D: '3D Image',
  AVAILABLE_LENSES: 'Available lenses for this product',
  DISTANCE: 'Distance',
  CLOSE_UP: 'Close-up',
  WITHOUT_DIOPTERS: 'Without diopters',
  WITH_DIOPTERS: 'With diopters',
  STANDARD_PRICE: 'Standard price',
  PRICE_DIOPTERS: 'Price depending of diopters',
  SPHERE: 'Sphere (SPH)',
  CYL: 'Cylinder (CYL)',
  PRICE: 'Price',
  DIOPTERS_MANDATORY: '*Filling out the price based on diopters is mandatory.',
  AVAILABLE_THICKNESS: 'Available lens thickness',
  STANDARD_THICKNESS: 'Standard - 1.50 index',
  THIN: 'Thin - 1.60 index',
  ULTRA_THIN: 'Ultra thin - 50% thinner than Standard',
  ASPHERICAL: 'Aspherical form (flat) - 1.67 index',
  THICKNESS_MANDATORY: '*Filling out the price based on lens thickness is mandatory.',
  AVAILABLE_ENHANCEMENTS: 'Available lens enhancements',
  COMPUTER: 'Smart phone filter',
  SHOCK_RESISTANT: 'Shock resistant',
  PHOTOCHROMATIC: 'Photochromatic',
  SUN_DIOPTERS: 'For sun with diopters',
  PROGRESSIVE: 'Progressive',
  ENHANCEMENTS_MANDATORY: '*Filling out the price based on lens enhancements is mandatory',
  PUBLISH: 'PUBLISH PRODUCT',
  SAVE_PRODUCT: 'SAVE PRODUCT',
  PERIOD_USE: 'Period of use',
  METHOD_WEARING: 'Method of wearing',
  TYPE: 'Type',
  PRODUCT_MANAGEMENT: 'Product Management',
  NEW_PRODUCT: 'NEW PRODUCT',
  SEARCH: 'Search',
  DELETE: 'Delete',
  MODIFY: 'Modify',
  APPLY: 'Apply',
  ORDER_BY: 'Order by:',
  PRODUCT_CATEGORY: 'Product category:',
  EYEGLASSES: 'Eyeglasses',
  SUNGLASSES: 'Sunglasses',
  COMPUTERGLASSES: 'Computer glasses',
  PRODUCT_NAME: 'Product name',
  VISIBILITY: 'Visibility',
  DATE: 'Date',
  DISCOUNT: 'Discount',
  PRODUCT_STATUS: 'Product status',
  ACTIONS: 'Actions',
  DATE_NEWEST: 'Date - the newest',
  DATE_OLDEST: 'Date - the oldest',
  CHEAPEST: 'Cheapest',
  IN_STOCK: 'In stock',
  MODIFY_PRODUCT: 'Modify the product',
  MANAGE_STOCK: 'Manage stock',
  MODIFY_STOCK: 'Modify stock',
  NUMBER_PRODUCTS_STOCK: 'Number of products in stock',
  SAVE: 'SAVE',
  PRODUCT_DETAILS: 'Product details',
  REMOVE_PRODUCT: 'REMOVE THE PRODUCT',
  GENERAL: 'General',
  PRODUCT_CODE: 'Product code',
  COLOR: 'Color',
  DETAILS: 'Details',
  AVAILABLE_LENSES_FOR: 'Available lenses for ',
  LENS_ENHANCEMENTS: 'Lens enhancements',
  LENS_THICKNESS: 'Lens thickness',
  IMAGES: 'Images',
  ALL_IMAGES: 'See all images +',
  IMPACT_RESISTANT: 'Impact resistant',
  ORDERED_PRODUCT: 'Ordered product',
  ORDER_NR: 'Order nr.',
  ORDER_STATUS: 'Order status',
  DISCOUNT_PROMOTION: 'Discount / Promotion',
  TOTAL_COST_ORDER: 'Total cost / order',
  ORDER_MANAGEMENT: 'Order Management',
  ORDER: 'Comanda',
  ORDER_DETAILS: 'Order details',
  PAYMENT_VIA: 'Payment via ',
  BILLING: 'Billing',
  SHIPPING: 'Shipping',
  REGISTRATION_DATE: 'Registration date',
  USER_CLIENT: 'User client',
  EMAIL_ADDRESS: 'Email address',
  ADDRESS: 'Adress',
  BILLING_DATE: 'Billing date',
  PHONE_NUMBER: 'Phone number',
  NOTE_PROVIDED: 'Note provided by the customer',
  PRODUCTS: 'Products',
  PRESCRIPTION: 'Prescription',
  OD_SPHERE: 'OD Sphere',
  OS_SPHERE: 'OS Sphere',
  PUPILLARY_DISTANCE: 'Pupillary distance value',
  THICKNESS: 'Thickness',
  TOTAL: 'Total',
  SEND_BILL: 'SEND BILL',
  DOWNLOAD_BILL: 'DOWNLOAD BILL',
  OPTICAL_ACCOUNT_DETAILS: 'Optical account details',
  ACCOUNT_DETAILS: 'Account details',
  OPTICAL_CABINET_NAME: 'Optical cabinet name',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  BANK_ACCOUNT_INFO: 'Bank account info',
  IBAN: 'IBAN',
  ACCOUNT_HOLDER: 'Account holder',
  BANK: 'Bank',
  MODIFY_ACCOUNT: 'Modify account details',
  EDIT_DETAILS: 'Edit details',
  FIELDS_MANDATORY: 'The fields marked with * are mandatory',
  LOCATIONS: 'Locations',
  LOCATION_DETAILS: 'Location details',
  COUNTY: 'County',
  TOWN: 'Town',
  STREET: 'Street',
  NUMBER: 'Number',
  ZIP: 'ZIP code',
  OTHER_DETAILS: 'Other details',
  EDIT_LOCATION: 'Edit location data',
  ADD_LOCATION: 'ADD LOCATION',
  ADD_PRODUCT: 'Add Product',
  EYESIGHT_LENSES: 'Contact Lenses',
  COSMETIC_LENSES: 'Cosmetic Lenses',
  ORDERS: 'Orders',
  SETTINGS: 'Settings',
  ACCOUNT_DATA: 'Account data',
  LOCATION: 'Location',
  DELETE_LOCATION: 'Delete location',
  CHOOSE_PARAMETERS: 'Choose parameters',
  DIOPTERS: 'Diopters',
  FROM: 'From',
  TO: 'To',
  RADIUS: 'Radius',
  DIAMETER: 'Diameter',
  CONFIRM_PASSWORD: 'Confirm password',
  MODIFY_PASSWORD: 'Modify password',
  ACTUAL_PASSWORD: 'Actual password',
  NEW_PASSWORD: 'New password',
  CHANGE_ORDER_STATUS: 'Change order status',
  DELETE_ORDER: 'Delete order',
  CURRENT_ORDER_STATUS: 'Current order status',
  UPDATE: 'UPDATE PRODUCT',
  INITIATED: 'Initiated',
  PAYMENT_REQUIRED: 'Payment required',
  IN_DELIVERY: 'In delivery',
  CANCELED: 'Canceled',
  COMPLETED: 'Completed',
  PROGRAM_CUSTOMER_SERVICE: 'Program customer service',
  NO_COLOR_SELECTED: 'No color selected',
  ONE_OPTION_SHOULD_SELECT: '*At least one option should be selected.',
  ROMANA: 'Romanian',
  ENGLEZA: 'English',
  BROWN: 'Brown',
  GREY: 'Grey',
  OTHER_CATEGORIES: 'Other categories',
  ADD_OTHERS: 'Add others',
  NAME: 'Name',
  PRICIEST: 'Priciest',
  ALL: 'All',
  COUNTRY: 'Country',
  AVAILABLE: 'Available',
  INCOLOR: 'No color',
  SOLUTII_ACCESORII: 'Solutions and accessories',
  PICATURI_OFTALMICE: 'Ophthalmic drops',
  SOLUTII_INTRETINERE_LENTILE: 'Lens maintenance solutions',
  SUPORT_LENTILE: 'Lens holder',
  SOLUTII_INTRETINERE_OCHELARI: 'Glasses maintenance solutions',
  LAVETE: 'Cloths',
  TOCURI_OCHELARI: 'Glasses case',
  ALTELE: 'Alimente de baza',
  WITHOUT_DIOPTER: 'Without diopter',
  SELECT_ALL_OPTIONS: 'Select all options ',
  PICKUP_LOCATION: "Pickup location",
  END_OF_DAY: "End of day",
  CONTACT_PERSON: "Contact person",
  PAID: "Paid",
  PAYMENT_FAILED: "Payment failed",
  DELIVERED: "Delivered",
  OD_CYL: 'OD Cylinder',
  OS_CYL: 'OS Cylinder',
  OD_AX: 'OD Ax',
  OS_AX: 'OS Ax',
  DOWNLOAD_AWB: 'DOWNLOAD AWB',
  PRINT_ORDER: 'PRINT ORDER',
  REQUEST_SHIPMENT: 'REQUEST SHIPMENT',
};
