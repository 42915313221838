export const TEXT_RO = {
    ADD_EYEGLASSES: 'Adaugă ochelari de vedere',
    ADD_SUNGLASSES: 'Adaugă ochelari de soare',
    ADD_COMPUTER_GLASSES: 'Adaugă ochelari calculator',
    ADD_LENSES: 'Adaugă lentile',
    ADD_COSMETIC_LENSES: 'Adaugă lentile cosmetice',
    PRODUCT_DETAILS_FILTERS: 'Detalii produs si filtre de căutare',
    BRAND: 'Brand',
    MODEL: 'Model',
    NUMBER_STOCK: 'Numărul de produse în stoc',
    FULL_PRICE: 'Preț întreg',
    DISCOUNT_PRICE: 'Reducere de preț',
    FINAL_PRICE: 'Preț final după reducere',
    DESCRIPTION: 'Descriere',
    FACE_SHAPE: 'Forma feței',
    FRAME_SHAPE: 'Formă ramă',
    FRAME_TYPE: 'Tip ramă',
    FRAME_MATERIALS: 'Material rame',
    LENS_TYPES: 'Tip lentile',
    CHARACTERISTICS: 'Caracteristici',
    FOR: 'Pentru',
    SIZE: 'Mărime',
    LENSES_WIDTH: 'Lățime lentile',
    TEMPLE_LENGTH: 'Lungime brațe',
    BRIDGE_WIDTH: 'Punte nazală',
    ADD_SIZE: 'Adaugă o nouă marime +',
    FRAME_COLOR: 'Culoare rame',
    LENSES_COLOR: 'Culoare lentile',
    PRODUCT_IMAGES: 'Imagini produs',
    ADD_IMAGE: '+ Adaugă imagine',
    IMAGE_3D: 'Imagine 3D',
    AVAILABLE_LENSES: 'Lentile disponibile pentru acest produs',
    DISTANCE: 'Depărtare',
    CLOSE_UP: 'Apropiere',
    WITHOUT_DIOPTERS: 'Fără dioptrii',
    WITH_DIOPTERS: 'Cu dioptrii',
    STANDARD_PRICE: 'Preț standard',
    PRICE_DIOPTERS: 'Preț în funcție de dioptrii',
    SPHERE: 'Sferă (SPH)',
    CYL: 'Cilindru (CYL)',
    PRICE: 'Preț',
    DIOPTERS_MANDATORY: '*Completarea prețului în funcție de dioptrii este obligatorie.',
    AVAILABLE_THICKNESS: 'Grosime lentile disponibile',
    STANDARD_THICKNESS: 'Standard - 1.50 index',
    THIN: 'Subțire - 1.60 index',
    ULTRA_THIN: 'Ultra subțire - cu 50% mai subțire decât Standard',
    ASPHERICAL: 'Forma asferică (plată) - 1.67 index',
    THICKNESS_MANDATORY: '*Completarea prețului în funcție de grosimea lentilelor este obligatorie.',
    AVAILABLE_ENHANCEMENTS: 'Îmbunătățiri lentile disponibile',
    COMPUTER: 'Cu filtru de telefon',
    SHOCK_RESISTANT: 'Rezistente la impact',
    PHOTOCHROMATIC: 'Fotocromatice',
    SUN_DIOPTERS: 'De soare cu dioptrii',
    PROGRESSIVE: 'Progresive',
    ENHANCEMENTS_MANDATORY: '*Completarea prețului în funcție de îmbunătățirile lentilei este obligatorie',
    PUBLISH: 'PUBLICĂ PRODUS',
    SAVE_PRODUCT: 'SALVEAZĂ PRODUS',
    PERIOD_USE: 'Perioada de utilizare',
    METHOD_WEARING: 'Mod de purtare',
    TYPE: 'Tip',
    PRODUCT_MANAGEMENT: 'Managementul Produselor',
    NEW_PRODUCT: 'PRODUS NOU',
    SEARCH: 'Caută',
    DELETE: 'Ștergere',
    MODIFY: 'Modifică',
    APPLY: 'Aplică',
    ORDER_BY: 'Ordonează după:',
    PRODUCT_CATEGORY: 'Categorie produs:',
    EYEGLASSES: 'Ochelari de vedere',
    SUNGLASSES: 'Ochelari de soare',
    COMPUTERGLASSES: 'Ochelari calculator',
    PRODUCT_NAME: 'Denumire produs',
    VISIBILITY: 'Vizibilitate',
    DATE: 'Dată',
    DISCOUNT: 'Reducere',
    PRODUCT_STATUS: 'Status produs',
    ACTIONS: 'Acțiuni',
    DATE_NEWEST: 'Dată - cel mai nou',
    DATE_OLDEST: 'Dată - cel mai vechi',
    CHEAPEST: 'Cel mai ieftin',
    IN_STOCK: 'În stoc',
    MODIFY_PRODUCT: 'Modifică produsul',
    MANAGE_STOCK: 'Gestionează stoc',
    MODIFY_STOCK: 'Modifică stocul',
    NUMBER_PRODUCTS_STOCK: 'Numărul de produse în stoc',
    SAVE: 'SALVEAZĂ',
    PRODUCT_DETAILS: 'Detalii produs ',
    REMOVE_PRODUCT: 'ȘTERGE PRODUSUL',
    GENERAL: 'General',
    PRODUCT_CODE: 'Cod produs',
    COLOR: 'Culoare',
    DETAILS: 'Detalii',
    AVAILABLE_LENSES_FOR: 'Lentile disponibile pentru ',
    LENS_ENHANCEMENTS: 'Îmbunătățiri lentile',
    LENS_THICKNESS: 'Grosime lentile',
    IMAGES: 'Imagini',
    ALL_IMAGES: 'Vezi toate imaginile +',
    IMPACT_RESISTANT: 'Rezistente la impact',
    ORDERED_PRODUCT: 'Produs comandat',
    ORDER_NR: 'Nr. comandă',
    ORDER_STATUS: 'Stare comandă',
    DISCOUNT_PROMOTION: 'Reducere / Promoție',
    TOTAL_COST_ORDER: 'Total cost / comandă',
    ORDER_MANAGEMENT: 'Mangement Comenzi',
    ORDER: 'Comanda',
    ORDER_DETAILS: 'Detalii comandă',
    PAYMENT_VIA: 'Plată prin ',
    BILLING: 'Facturare',
    SHIPPING: 'Livrare',
    REGISTRATION_DATE: 'Data înregistrării ',
    USER_CLIENT: 'Client utilizator ',
    EMAIL_ADDRESS: 'Adresă e-mail',
    ADDRESS: 'Adresă',
    BILLING_DATE: 'Data facturării',
    PHONE_NUMBER: 'Telefon',
    NOTE_PROVIDED: 'Notă furnizată de client',
    PRODUCTS: 'Produse',
    PRESCRIPTION: 'Rețetă',
    OD_SPHERE: 'Sferă OD',
    OS_SPHERE: 'Sferă OS',
    PUPILLARY_DISTANCE: 'Valoare distanță pupilară',
    THICKNESS: 'Grosime',
    TOTAL: 'Total',
    SEND_BILL: 'TRIMITE FACTURA',
    DOWNLOAD_BILL: 'DESCARCĂ FACTURA',
    OPTICAL_ACCOUNT_DETAILS: 'Date cont optică',
    ACCOUNT_DETAILS: 'Date cont',
    OPTICAL_CABINET_NAME: 'Nume optică',
    EMAIL: 'Email',
    PASSWORD: 'Parolă',
    BANK_ACCOUNT_INFO: 'Info cont bancar',
    IBAN: 'IBAN',
    ACCOUNT_HOLDER: 'Titular cont',
    BANK: 'Banca',
    MODIFY_ACCOUNT: 'Modifică date cont',
    EDIT_DETAILS: 'Modifică date',
    FIELDS_MANDATORY: 'Câmpurile marcate cu * sunt obligatorii',
    LOCATIONS: 'Locații',
    LOCATION_DETAILS: 'Date locație',
    COUNTY: 'Județ',
    TOWN: 'Localitate',
    STREET: 'Stradă',
    NUMBER: 'Număr',
    ZIP: 'Cod poștal',
    OTHER_DETAILS: 'Alte detalii',
    EDIT_LOCATION: 'Modifică date locație',
    ADD_LOCATION: 'ADAUGĂ LOCAȚIE',
    ADD_PRODUCT: 'Adaugă Produs',
    EYESIGHT_LENSES: 'Lentile de contact',
    COSMETIC_LENSES: 'Lentile cosmetice',
    ORDERS: 'Comenzi',
    SETTINGS: 'Setări',
    ACCOUNT_DATA: 'Date cont',
    LOCATION: 'Locație',
    DELETE_LOCATION: 'Ștergere locație',
    CHOOSE_PARAMETERS: 'Alegeți parametrii',
    DIOPTERS: 'Dioptrii',
    FROM: 'De la',
    TO: 'Până la',
    RADIUS: 'Rază de curbură',
    DIAMETER: 'Diametru',
    CONFIRM_PASSWORD: 'Confirmă parola',
    MODIFY_PASSWORD: 'Modifică parola',
    ACTUAL_PASSWORD: 'Parola actuală',
    NEW_PASSWORD: 'Noua parolă',
    CHANGE_ORDER_STATUS: 'Modifică stare comandă',
    DELETE_ORDER: 'Ștergere comandă',
    CURRENT_ORDER_STATUS: 'Stare actuală comandă',
    UPDATE: 'ACTUALIZEAZĂ PRODUS',
    INITIATED: 'Initiated',
    PAYMENT_REQUIRED: 'Payment required',
    IN_DELIVERY: 'In delivery',
    CANCELED: 'Canceled',
    COMPLETED: 'Completed',
    PROGRAM_CUSTOMER_SERVICE: 'Program serviciu clienți',
    NO_COLOR_SELECTED: 'Nicio culoare selectată',
    ONE_OPTION_SHOULD_SELECT: '*Trebuie selectată cel puțin o opțiune.',
    ROMANA: 'Română',
    ENGLEZA: 'Engleză',
    BROWN: 'Maro',
    GREY: 'Gri',
    OTHER_CATEGORIES: 'Alte categorii',
    ADD_OTHERS: 'Adaugă altele',
    NAME: 'Nume',
    PRICIEST: 'Cel mai scump',
    ALL: 'Toate',
    COUNTRY: 'Țară',
    AVAILABLE: 'Disponibil',
    INCOLOR: 'Incolor',
    SOLUTII_ACCESORII: 'Soluții și accesorii',
    PICATURI_OFTALMICE: 'Picături oftalmice',
    SOLUTII_INTRETINERE_LENTILE: 'Soluții întreținere lentile',
    SUPORT_LENTILE: 'Suport lentile',
    SOLUTII_INTRETINERE_OCHELARI: 'Soluții întreținere ochelari',
    LAVETE: 'Lavete',
    TOCURI_OCHELARI: 'Tocuri ochelari',
    ALTELE: 'Alimente de baza',
    WITHOUT_DIOPTER: 'Fără dioptrie',
    SELECT_ALL_OPTIONS: 'Selectează toate opțiunile ',
    PICKUP_LOCATION: "Locatie ridicare produse",
    END_OF_DAY: "Ora inchidere",
    CONTACT_PERSON: "Persoana contact",
    PAID: "Paid",
    PAYMENT_FAILED: "Payment failed",
    DELIVERED: "Delivered",
    OD_CYL: 'Cilindru OD',
    OS_CYL: 'Cilindru OS',
    OD_AX: 'Ax OD',
    OS_AX: 'Ax OS',
    DOWNLOAD_AWB: 'DESCARCĂ AWB',
    PRINT_ORDER: 'PRINTEAZĂ COMANDA',
    REQUEST_SHIPMENT: 'SOLICITA CURIER',
  };
  