import { Button, makeStyles, Typography } from '@material-ui/core';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import Header from '../../components/Header';
import { env } from '../../config';
import { showSnack } from '../../state/actions';
import {
  createShipmentCargus,
  createShipmentUps,
  getOrderById,
} from '../../state/commands/order';
import { OrderCompleteData } from '../../state/types';
import { SnackbarVariant } from '../../utils/constants';
import { ComponentToPrint } from './OrderDetails';

const OrdersDetailsContainer: FC<{}> = () => {
  const classes = useStyles();
  const [nrOrder, setNrOrder] = useState<string>('');

  const [orderData, setOrderData] = useState<OrderCompleteData>();
  const { t } = useTranslation();

  const location = useLocation();

  useEffect(() => {
    const state = location.state as string;
    setNrOrder(state);
  }, [location]);

  useEffect(() => {
    getOrder(nrOrder.slice(1, nrOrder.length));
  }, [nrOrder]);

  const getOrder = async (id: string) => {
    if (id !== '') {
      try {
        const response = await getOrderById(id);
        setOrderData(response.data);
      } catch (e) {
        showSnack(e.message, SnackbarVariant.error);
      }
    }
  };

  const openBillPage = () => {
    if (typeof orderData !== 'undefined' && orderData.invoice_url !== null) {
      window.open(orderData.invoice_url, '_blank');
    }
  };

  const openAwbPage = () => {
    console.log(orderData);
    const carrier = orderData?.general.carrier;
    const id = nrOrder.slice(1, nrOrder.length);
    if (typeof orderData !== 'undefined') {
      if (carrier === 'cargus' && orderData.awb !== null) {
        // const env =  'https://api.eyes-aid.com/';
        let awbUrl = env + 'api/cargus/getAwb/' + orderData.awb + '/2';
        window.open(awbUrl, '_blank');
      } else if (carrier === 'ups') {
        let awbUrl = env + 'api/ups/get-awb/' + id;
        window.open(awbUrl, '_blank');
      }
    }
  };

  const requestShipment = async () => {
    const id = nrOrder.slice(1, nrOrder.length);
    const carrier = orderData?.general.carrier;
    if (id !== '' && typeof orderData !== 'undefined') {
      if (carrier === 'ups') {
        try {
          const response = await createShipmentUps(id);
          console.log(response.data);
        } catch (e) {
          showSnack(e.message, SnackbarVariant.error);
        }
      } else if (carrier === 'cargus') {
        try {
          const response = await createShipmentCargus(id);
          console.log(response.data);
        } catch (e) {
          showSnack(e.message, SnackbarVariant.error);
        }
      }
    }
  };

  const buttons = (
    <div className={classes.buttons}>
      <Button size="large" variant="contained" color="primary">
        <Typography color="textSecondary" variant={'body1'}>
          {t('SEND_BILL')}
        </Typography>
      </Button>
      <Button size="large" variant="contained" color="primary" onClick={openBillPage}>
        <Typography color="textSecondary" variant={'body1'}>
          {t('DOWNLOAD_BILL')}
        </Typography>
      </Button>
      <Button size="large" variant="contained" color="primary" onClick={openAwbPage}>
        <Typography color="textSecondary" variant={'body1'}>
          {t('DOWNLOAD_AWB')}
        </Typography>
      </Button>
      <ReactToPrint
        trigger={() => (
          <Button size="large" variant="contained" color="primary">
            {t('PRINT_ORDER')}
          </Button>
        )}
        content={() => componentRef.current}
      />
      <Button size="large" variant="contained" color="primary" onClick={requestShipment}>
        <Typography color="textSecondary" variant={'body1'}>
          {t('REQUEST_SHIPMENT')}
        </Typography>
      </Button>
    </div>
  );

  const componentRef = useRef(null);
  return (
    <div className={classes.root}>
      <Header tabName={'Comanda ' + nrOrder} />
      <ComponentToPrint ref={componentRef} />
      <div className={classes.buttons}>{buttons}</div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  content: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  firstRow: {
    display: 'grid',
    gridTemplateColumns: '0.2fr 0.35fr 0.35fr',
  },
  secondRow: {
    display: 'grid',
    gridTemplateColumns: '0.2fr 0.35fr 0.35fr 0.1fr',
  },
  title: {
    height: '52px',
  },
  inlineText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: `0px ${theme.spacing(4)}px`,
  },
  greyLine: {
    height: '1px',
    width: '100%',
    backgroundColor: theme.palette.grey[300],
  },
  bold: {
    fontWeight: 700,
  },
  imageAndText: {
    display: 'grid',
    justifyItems: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.palette.grey[300]}`,
    [theme.breakpoints.down('md')]: {
      gridRow: '1/2',
    },
  },
  eyeglassText: {
    display: 'grid',
    justifyItems: 'center',
  },
  specs: {
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  details: {
    display: 'flex',
    justifyContent: 'center',
    padding: `${theme.spacing(2)}px 0px`,
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  detailsWithBorder: {
    display: 'flex',
    justifyContent: 'center',
    padding: `${theme.spacing(2)}px 0px`,
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  justifyLeft: {
    justifyContent: 'left',
  },
  eyeglassPrice: {
    padding: `${theme.spacing(1)}px 0px`,
    fontWeight: 'bold',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  centerGrid: {
    display: 'grid',
    justifyItems: 'center',
    paddingBottom: theme.spacing(3),
  },
  border: {
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  underHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  alignRight: {
    display: 'grid',
    justifyItems: 'end',
    paddingRight: theme.spacing(3),
  },
  padding: {
    paddingRight: theme.spacing(2),
  },
  buttons: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    gridGap: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  addressAndPen: {
    display: 'flex',
    gridGap: theme.spacing(0.5),
    alignItems: 'center',
  },
  secondRowItem: {
    display: 'grid',
  },
}));

export default OrdersDetailsContainer;
